import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import DashboardLayout from '../../layouts/dashboardlayout';
import { Button, Card, CardBody, Spinner, Alert, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link } from 'gatsby';
import { requestGetWithKey, requestDeleteWithKey } from '../../helpers/utils';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const { SearchBar } = Search;

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [modal, setModal] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [selfUserId, setSelfUserId] = useState('');

  useEffect(() => {
    setLoading(true);
    
    const getUsers = [`api/users`]
    requestGetWithKey(getUsers)
      .then(response => {
        const userData = Array.isArray(response.message) ? response.message : [];
        setUsers(userData);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching users:', error);
        setError('Error fetching users. Please try again later.');
        setLoading(false);
      });

    // Fetch current user's ID
    const getCurrentUserId = ['api/profile'];
    requestGetWithKey(getCurrentUserId)
      .then(response => {
        setSelfUserId(response.message.id);
      })
      .catch(error => {
        console.error('Error fetching current user ID:', error);
      });

  }, []);

  useEffect(() => {
    if (error) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [error]);

  const toggleModal = () => setModal(!modal);

  const handleDeleteClick = (id) => {
    if (id === selfUserId) {
      setError("You cannot delete yourself.");
      return;
    }
    setUserIdToDelete(id);
    toggleModal();
  };

  const handleDeleteUser = () => {
    toggleModal();
    setLoading(true);
    const deleteUsers = [`api/users/${userIdToDelete}`]
    requestDeleteWithKey(deleteUsers)
      .then(response => {
        setUsers(users.filter(user => user.id !== userIdToDelete));
        setLoading(false);
        setError(null);
      })
      .catch(error => {
        console.error('Error deleting user:', error);
        setError('Error deleting user. Please try again later.');
        setLoading(false);
      });
  };

  const columns = [
    {
      dataField: 'firstName',
      text: 'First Name',
      sort: true,
      headerStyle: { width: '15%' }
    },
    {
      dataField: 'lastName',
      text: 'Last Name',
      sort: true,
      headerStyle: { width: '15%' }
    },
    {
      dataField: 'email',
      text: 'Email',
      sort: true,
      headerStyle: { width: '20%' }
    },
    {
      dataField: 'winery.name',
      text: 'Winery',
      sort: true,
      headerStyle: { width: '20%' }
    },
    {
      dataField: 'groups',
      text: 'Groups',
      formatter: (cell, row) => row.groups.map(group => group.name).join(', '),
      sort: true,
      headerStyle: { width: '20%' }
    },
    {
      dataField: 'actions',
      text: 'Actions',
      formatter: (cell, row) => (
        <div style={{ cursor: 'pointer' }}>
          <Link to={`/admin/users/${row.id}/edit`} className="mr-2">
            <FontAwesomeIcon icon={faEdit} size="lg" style={{ cursor: 'pointer' }} />
          </Link>
          <FontAwesomeIcon icon={faTrashAlt} size="lg" color="red" style={{ cursor: 'pointer' }} onClick={() => handleDeleteClick(row.id)} />
        </div>
      ),
      headerStyle: { width: '10%' }
    }
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Winery Portal - Users</title>
      </Helmet>
      <DashboardLayout>
        <Card className="border-bottom mb-3">
          <CardBody className="p-lg-4 card-inside" style={{height: 'auto'}}>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h1 className="font-weight-semi-bold" style={{ fontSize: '20px', color: 'rgb(19, 19, 20)' }}>Users</h1>
              <Link to={"/admin/users/add"}>
                <Button color="primary">Add User</Button>
              </Link>
            </div>
            {error && <Alert color="danger">{error}</Alert>}
            {loading ? (
              <div className="text-center pt-6">
                <Spinner type="grow" style={{ width: '3rem', height: '3rem', color: '#77b204' }} />
              </div>
            ) : (
              <ToolkitProvider
                keyField="id"
                data={users}
                columns={columns}
                search
              >
                {
                  props => (
                    <div>
                      <SearchBar
                        {...props.searchProps}
                        style={{ width: "400px", height: "40px" }}
                      />
                      <BootstrapTable
                        {...props.baseProps}
                        pagination={paginationFactory({
                          sizePerPage: 10,
                          sizePerPageList: [
                            { text: '10', value: 10 },
                            { text: '20', value: 20 },
                            { text: '50', value: 50 },
                            { text: '100', value: 100 }
                          ],
                          showTotal: true,
                          alwaysShowAllBtns: true,
                        })}
                        bootstrap4
                        hover
                        condensed
                        wrapperClasses="table-responsive"
                        rowClasses="align-middle"
                        headerWrapperClasses="thead-light"
                      />
                      <div className="d-flex justify-content-end mt-3">
                        {props.paginationTableProps && props.paginationTableProps.pagination}
                      </div>
                    </div>
                  )
                }
              </ToolkitProvider>
            )}
          </CardBody>
        </Card>

        <Modal isOpen={modal} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Confirm Delete</ModalHeader>
          <ModalBody>
            Are you sure you want to delete this user?
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={handleDeleteUser}>Delete</Button>
            <Button color="secondary" onClick={toggleModal}>Cancel</Button>
          </ModalFooter>
        </Modal>    

      </DashboardLayout>
    </>
  );
};

export default Users;
